import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { brandColor } from '../../style/brandConfig'
import NavItem from './components/NavItem'
import MoreSection from './components/MoreSection'
import ProfileSection from './components/ProfileSection'
import { WindowSize } from '../../constants/enums'
import { hexToRgb } from '../../utils/colors'
import useGlobal from '../../utils/hooks/useGlobal'
import { useSelector } from 'react-redux'
import { selectBrandData, selectWindowSize, selectHeader } from '../../store/base/commonSlice'

type HeaderProps = {
  theme?: string
  scrolling: boolean
  data?: {
    links?: Link[]
    more?: Link[]
    liveNow?: Link[]
  }
}

const Header = ({ scrolling }: HeaderProps) => {
  const location = useLocation()
  const windowSize = useSelector(selectWindowSize)
  const brand = useSelector(selectBrandData)
  const header = useSelector(selectHeader)

  const gradientHeight = header === 'search' ? 205 : 105
  const blurHeight = header === 'search' ? 205 : 105

  const { header: data } = useGlobal()

  const hideNav =
    location?.pathname === '/sign-in' ||
    location?.pathname === '/reset-password' ||
    location?.pathname === '/registration' ||
    location?.pathname.split('/')[1] === 'subscribe' ||
    location?.pathname.split('/')[1] === 'purchase'

  const navigate = useNavigate()

  const allLinks: Link[] = data?.links?.length ? data?.links : []
  const more = data?.more?.length ? data?.more : []

  const headerLinks = allLinks.filter((link) => !link.custom)
  const customLinks = allLinks.filter((link) => link.custom)

  const [headerCustomLinks, setHeaderCustomLinks] = useState<Link[]>([])
  const [moreLinks, setMoreLinks] = useState<Link[]>([])

  useEffect(() => {
    if (!customLinks?.length && headerLinks.length) {
      setHeaderCustomLinks(headerLinks)
      setMoreLinks(more)
    }
  }, [data, headerLinks.toString()])

  useEffect(() => {
    setData()
  }, [windowSize, data])

  const setData = () => {
    if (windowSize === WindowSize.DesktopFull) {
      setHeaderCustomLinks(customLinks)
      setMoreLinks(more)
    } else if (windowSize === WindowSize.DesktopLarge) {
      setHeaderCustomLinks(customLinks.slice(0, 3))
      setMoreLinks(customLinks.slice(3).concat(more))
    } else if (windowSize === WindowSize.DesktopMedium) {
      setHeaderCustomLinks(customLinks.slice(0, 2))
      setMoreLinks(customLinks.slice(2, 3).concat(more))
    } else if (windowSize === WindowSize.DesktopSmall) {
      setHeaderCustomLinks(customLinks.slice(0, 1))
      setMoreLinks(customLinks.slice(1, 3).concat(more))
    } else if (windowSize === WindowSize.Mobile) {
      setHeaderCustomLinks([])
      setMoreLinks(customLinks.concat(more))
    }
  }

  const gradientStyle = scrolling
    ? { ...styles.gradient, height: gradientHeight }
    : { ...styles.gradient, opacity: 0, height: gradientHeight }

  const blurStyle = scrolling
    ? { ...styles.blur, height: blurHeight }
    : { ...styles.blur, height: blurHeight, opacity: 0 }

  if (windowSize === WindowSize.Mobile) {
    gradientStyle.height = header === 'search' ? 165 : 77.5
    blurStyle.height = header === 'search' ? 165 : 77.5
  }

  /*const leftContainerStyle =
    windowSize === WindowSize.Mobile
      ? { ...styles.leftContainer, ...styles.leftContainerMobile }
      : { ...styles.leftContainer, ...styles.leftContainerDesktop }*/

  const rightContainerStyle =
    windowSize === WindowSize.Mobile
      ? { ...styles.rightContainer, ...styles.rightContainerMobile }
      : { ...styles.rightContainer, ...styles.rightContainerDesktop }

  /*const logoWrapperStyle =
    windowSize === WindowSize.Mobile
      ? { ...styles.logoWrapper, ...styles.logoWrapperMobile }
      : { ...styles.logoWrapper, ...styles.logoWrapperDesktop }

  const logoStyle = windowSize === WindowSize.Mobile ? { ...styles.logo } : { ...styles.logo, ...styles.logoDesktop }*/

  const brandStyle = windowSize === WindowSize.Mobile ? { ...styles.brand, ...styles.mobileBrand } : styles.brand

  const menuStyle = windowSize === WindowSize.Mobile ? { ...styles.menu, ...styles.mobileMenu } : styles.menu

  const linksStyle = windowSize === WindowSize.Mobile ? { ...styles.links, ...styles.mobileLinks } : styles.links

  const wrapperStyle =
    windowSize === WindowSize.Mobile ? { ...styles.wrapper, ...styles.mobileWrapper } : styles.wrapper

  return hideNav ? null : allLinks?.length ? (
    <div>
      <div style={blurStyle} />
      <div style={gradientStyle} />
      <div style={styles.container}>
        <div style={wrapperStyle}>
          <div style={brandStyle} onClick={() => navigate('/')}>
            <img src={brand?.icon?.src} alt="brand" style={styles.image} onClick={() => navigate('/')} />
          </div>
          <div style={menuStyle}>
            <div style={linksStyle}>
              {headerLinks.map((item: Link, index: number) => (
                <NavItem key={index} item={item} windowSize={windowSize} />
              ))}
              {windowSize === WindowSize.Mobile ? <MoreSection items={moreLinks} windowSize={windowSize} /> : null}
            </div>
            {windowSize !== WindowSize.Mobile ? (
              <>
                <div style={styles.divider} />
                <div style={linksStyle}>
                  {headerCustomLinks.map((item: Link, index: number) => {
                    return <NavItem key={index} item={item} windowSize={windowSize} />
                  })}
                  <MoreSection items={moreLinks} windowSize={windowSize} />
                </div>
              </>
            ) : null}
          </div>
          <div style={rightContainerStyle}>
            <ProfileSection />
          </div>
        </div>
      </div>
    </div>
  ) : null
}

const { r, g, b } = hexToRgb(brandColor)

const styles: { [index: string]: React.CSSProperties } = {
  container: {
    position: 'fixed',
    left: 0,
    top: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    zIndex: 10,
    //justifyContent: 'space-between',,
  },
  wrapper: {
    paddingTop: 17,
    paddingBottom: 17,
    paddingLeft: 35,
    paddingRight: 35,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    zIndex: 10,
  },
  wrapperMobile: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
    paddingRight: 24,
  },
  brand: {
    height: 70,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
  },
  mobileBrand: {
    display: 'flex',
    height: 40,
  },
  image: {
    height: process.env.REACT_APP_BRAND_HEIGHT || '100%',
  },
  menu: {
    paddingLeft: 60,
    paddingTop: 18,
    paddingBottom: 18,
    display: 'flex',
  },
  mobileMenu: {
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 28,
    alignItems: 'center',
  },
  links: {
    paddingTop: 7,
    paddingBottom: 7,
    display: 'flex',
    gap: 30,
  },
  mobileLinks: {
    paddingTop: 0,
    paddingBottom: 0,
    gap: 15,
  },
  divider: {
    height: '100%',
    width: 1,
    marginRight: 30,
    marginLeft: 30,
    backgroundColor: '#404040',
  },
  containerDesktop: {
    backgroundColor: 'transparent',
    background: 'none',
  },
  gradient: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 105,
    mixBlendMode: 'multiply',
    opacity: 0.7,
    transition: 'opacity 0.35s ease-out',
    background: 'transparent linear-gradient(180deg, #000000 0%, #404040 100%) 0% 0% no-repeat padding-box',
    zIndex: 3,
  },
  blur: {
    position: 'fixed',
    top: 0,
    width: '100%',
    height: 105,
    opacity: 1,
    transition: 'opacity 0.35s ease-out',
    background: `rgb(${r}, ${g}, ${b}, 0.6) 0% 0% no-repeat padding-box`,
    backdropFilter: 'blur(30px)',
    WebkitBackdropFilter: 'blur(30px)',
    zIndex: 3,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  leftContainerDesktop: {
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 32,
  },
  leftContainerMobile: {
    justifyContent: 'space-between',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 10,
    flex: 1,
    justifyContent: 'right',
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 'bold',
  },
  rightContainerDesktop: {
    //marginTop: 40,
    display: 'flex',
    flex: 1,
    justifyContent: 'right',
    color: 'white',
    font: 'SF Pro Text',
    fontWeight: 'bold',
  },
  rightContainerMobile: {
    //justifyContent: 'space-between',
    //marginRight: 27,
    marginTop: -2,
  },
  logoWrapper: {
    display: 'flex',
    height: 65,
    width: 65,
    alignItems: 'center',
    justifyContent: 'center',
  },
  logoWrapperDesktop: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: -15,
  },
  logoWrapperMobile: {
    marginLeft: 35,
    marginRight: 25,
    marginTop: 5,
  },
  logo: {
    minWidth: '100%',
    cursor: 'pointer',
  },
  logoDesktop: {
    padding: 8,
  },
  underline: {
    transition: 'opacity 0.3s, transform',
    height: 4,
    backgroundColor: brandColor, // TODO get this value from the brand config
  },
  moreWrapper: {
    marginTop: 1,
  },
}
export default Header
